import { InjectionKey } from 'vue';
import { ActionTree, createStore, GetterTree, MutationTree, Store, StoreOptions, useStore } from 'vuex';
import { EDeviceTypeName } from '../types';
import { Device, DeviceHms, DeviceOsd, DeviceStatus, DockOsd, GatewayOsd, OSDVisible } from '../types/device';
import { getLayers } from '/@/api/layer';
import { getionoeffect } from '/@/api/dianci';

import { LayerType } from '/@/types/mapLayer';
import { WaylineFile } from '/@/types/wayline';
import { DevicesCmdExecuteInfo } from '/@/types/device-cmd';
import { wgs84togcj02, forceGcj02towgs84 } from '../utils/gpsto';
import dockSvg from '/@/assets/icons/dock.svg';
import { getRoot } from '../root';

const root = getRoot();

const initStateFunc = () => ({
	Layers: [
		{
			name: 'default',
			id: '',
			is_distributed: true,
			elements: [],
			is_check: false,
			is_select: false,
			type: 1,
		},
		{
			name: 'share',
			id: '',
			is_distributed: true,
			elements: [],
			is_check: false,
			is_select: false,
			type: 2,
		},
	],

	livestreamOthersVisible: false,
	livestreamAgoraVisible: false,
	// 窗口的状态
	modalData: {
		wayline: false,
		device: false,
	} as {
		wayline: boolean;
		device: boolean;
	},

	dianli: {} as {
		[key: string]: {
			// 当前查询状态
			status: string;
			lon: string;
			lat: string;
			//当前电离层 状态
			label: number;
		};
	},
	currentShip: '' as string,
	shipObject: {} as {
		[key: string]: {
			id: string;
			name: string;
			isStartLive: number;
			liveUrl: string;
			latitude: number;
			longitude: number;
			heartbeatTime: number;
			visible: boolean;
		};
	},
	// 位置数据
	positionData: {
		// 飞机位置
		drone: {},
		// 遥控器位置
		gateway: {},
		// 机场位置
		dock: {},
		//无人机传位置
		ship: {},
	} as {
		drone: {
			[key: string]: {
				height?: string | number;
				longitude: string | number;
				latitude: string | number;
				lon: string | number;
				angle: string | number;
				lat: string | number;
				marker: null;
				dock_sn: string;
			};
		};
		gateway: {
			[key: string]: {
				longitude: string | number;
				latitude: string | number;
				lon: string | number;
				lat: string | number;
				marker: null;
			};
		};
		dock: {
			[key: string]: {
				longitude: string | number;
				latitude: string | number;
				lon: string | number;
				lat: string | number;
				mode_code: string | number;
				marker: null;
				dock_sn: string;
			};
		};
		ship: {
			[key: string]: {
				longitude: string | number;
				latitude: string | number;
				marker: null;
				name: string;
			};
		};
	},
	layerBaseInfo: {} as {
		[key: string]: string;
	},
	drawVisible: false,
	coverList: [] as any,
	//是否自动生成(点击标记线和面自动shne)
	ifAutomatically: false,
	polylineOptions: [] as any,
	polygonOptions: [] as any,
	wsEvent: {
		mapElementCreat: {},
		mapElementUpdate: {},
		mapElementDelete: {},
	},
	deviceStatusEvent: {
		deviceOnline: {} as DeviceStatus,
		deviceOffline: {},
	},
	markerInfo: {
		coverMap: {} as {
			[sn: string]: any;
		},
		polyLineMap: {} as {
			[sn: string]: any;
		},
		pathMap: {} as {
			[sn: string]: any[];
		},
	},
	peopleState: {},
	deviceState: {
		// remote controller, dock
		gatewayInfo: {} as {
			[sn: string]: GatewayOsd;
		},
		// drone
		deviceInfo: {} as {
			[sn: string]: DeviceOsd;
		},
		dockInfo: {} as {
			[sn: string]: DockOsd;
		},
		currentSn: '',
		currentType: -1,
	},
	fyDockShow: false,
	fuyaDockTask: {
		end: {
			status: false,
			fuyaDockTask: {},
		},
		start: {},
	} as {
		end: {
			status: boolean;
			fuyaDockTask: any;
		};
		start: {
			status: boolean;
			fuyaDockTask: any;
		};
	},
	osdVisible: {
		// osd 显示设备相关信息
		sn: '',
		callsign: '',
		model: '',
		visible: false,
		isFuya: false,
		gateway_sn: '',
		is_dock: false,
		isUnmannedDeviceRouterShow: false,
		payloads: null,
	} as OSDVisible,
	osdDataInfo: {
		sn: '',
		callsign: '',
		model: '',
		visible: false,
		isFuya: false,
		gateway_sn: '',
		is_dock: false,
		payloads: null,
	} as OSDVisible,
	waylineInfo: {} as WaylineFile,

	taskRefresh: {
		data: '0',
	} as { data: string },

	dockInfo: {} as Device,
	hmsInfo: {} as {
		[sn: string]: DeviceHms[];
	},
	centerPointData: {
		center: [],
	} as { [key: string]: number[] },
	isOpenLiveBroadcast: {
		sn: '',
		isOpen: false,
	},
	// 机场指令执行状态信息
	devicesCmdExecuteInfo: {} as DevicesCmdExecuteInfo,
	mqttState: null as any, // mqtt 实例
	clientId: '', // mqtt 连接 唯一客户端id
	//航线单条信息
	waypoint: {
		routeInfo: [],
		startPoint: {},
		map: {
			center: [],
		},
	},

	clueAiLiveList: [],
});
const correspondence: { [key: string]: string } = {};

export type RootStateType = ReturnType<typeof initStateFunc>;

const getters: GetterTree<RootStateType, RootStateType> = {};
const mutations: MutationTree<RootStateType> = {
	SET_LAYER_INFO(state, info) {
		state.Layers = info;
	},
	SET_DEVICE_INFO(state, info) {
		// 判断经纬度的字段是否存在
		// if(info.host && info.host.hasOwnProperty('latitude') && info.host.hasOwnProperty("longitude")){
		//   // 设置经纬度
		//   if(!info.host.latitude){
		//     info.host.latitude = 32.059352
		//     info.host.longitude = 118.796623
		//   }
		// }
		let lon;
		let lat;
		if (info.host && info.host.longitude && info.host.latitude) {
			lon = info.host.longitude;
			lat = info.host.latitude;
			const gps = wgs84togcj02(Number(info.host.longitude), Number(info.host.latitude));
			info.host.longitude = gps[0];
			info.host.latitude = gps[1];
			info.host.lon = lon;
			info.host.lat = lat;
		}
		if (!state.positionData.drone[info.sn]) {
			state.positionData.drone[info.sn] = {
				height: info.host.height,
				longitude: info.host.longitude,
				latitude: info.host.latitude,
				lon,
				lat,
				angle: info.host.attitude_head,
				status: true,
				dock_sn: correspondence[info.sn] || '',
			};
		} else {
			state.positionData.drone[info.sn].lon = lon;
			state.positionData.drone[info.sn].lat = lat;
			(state.positionData.drone[info.sn].height = info.host.height),
				(state.positionData.drone[info.sn].angle = info.host.attitude_head),
				(state.positionData.drone[info.sn].longitude = info.host.longitude);
			state.positionData.drone[info.sn].latitude = info.host.latitude;
		}
		state.deviceState.deviceInfo[info.sn] = info.host;
		state.positionData.drone[info.sn].dock_sn = correspondence[info.sn] || '';
		/* state.positionData.drone[info.sn].longitude = 118.8370103964207;
		state.positionData.drone[info.sn].lon = 118.8370103964207;
		state.positionData.drone[info.sn].latitude = 32.00111883284443;
		state.positionData.drone[info.sn].lat = 32.00111883284443; */
		state.deviceState.deviceInfo[info.sn].dock_sn = correspondence[info.sn] || '';
		state.deviceState.currentSn = info.sn;
		state.deviceState.currentType = EDeviceTypeName.Aircraft;
	},
	SET_LIVESTREAM_OTHERS_VISIBLE(state, bool) {
		state.livestreamOthersVisible = bool;
	},
	SET_LIVESTREAM_AGORA_VISIBLE(state, bool) {
		state.livestreamAgoraVisible = bool;
	},
	SET_CLUE_AI_LIVE_LIST(state, info) {
		state.clueAiLiveList = info;
	},

	SET_TASK_REFRESH(state, info) {
		//
		state.taskRefresh.data = info;
	},
	SET_FYDOCK(state, show: boolean) {
		state.fyDockShow = show;
	},
	SET_FUYA_DOCK_TASK_END(state, data) {
		state.fuyaDockTask.end = data;
	},
	SET_GATEWAY_INFO(state, info) {
		// 设置数据 具体逻辑
		// 判断是否有数据，没有数据的话给当前遥控器的点为中心点
		// 数据缓存，当下一个遥控器的点距离此点超过3m则数据替换更新
		//

		if (info.host && info.host.longitude && info.host.latitude) {
			let lon = info.host.longitude;
			let lat = info.host.latitude;
			const gps = wgs84togcj02(Number(info.host.longitude), Number(info.host.latitude));
			info.host.longitude = gps[0];
			info.host.latitude = gps[1];
			if (!state.positionData.gateway[info.sn]) {
				state.positionData.gateway[info.sn] = {
					longitude: info.host.longitude,
					latitude: info.host.latitude,
					lon,
					lat,
					status: true,
					marker: null,
				};
			} else {
				state.positionData.gateway[info.sn].longitude = info.host.longitude;
				state.positionData.gateway[info.sn].latitude = info.host.latitude;
			}
		}

		state.deviceState.gatewayInfo[info.sn] = info.host;
		state.deviceState.currentSn = info.sn;
		state.deviceState.currentType = EDeviceTypeName.Gateway;
	},
	SET_PEOPLE_INFO(state, info) {
		const fromId = info.from.userId;
		if (!state.peopleState[fromId]) {
			state.peopleState[fromId] = {};
		}
		let [lat, lon] = forceGcj02towgs84(info.res.latitude, info.res.longitude);
		state.peopleState[fromId] = { ...info, positionData: { ...info.res, lat, lon } };
	},
	SET_DOCK_INFO(state, info) {
		if (Object.keys(info.host).length === 0) {
			return;
		}
		// (async () => {
		// 	let dianli: {
		// 		[key: string]: {
		// 			// 当前查询状态
		// 			status: string;
		// 			lon: string;
		// 			lat: string;
		// 			//当前电离层 状态
		// 			label: number;
		// 		};
		// 	} = state.dianli;
		// 	if (!dianli[info.sn] || !dianli[info.sn].status) {
		// 		if (info && info.host && info.host.longitude && info.host.latitude) {
		// 			dianli[info.sn] = {
		// 				lon: info.host.longitude,
		// 				lat: info.host.latitude,
		// 				status: 'opening',
		// 				label: -1,
		// 			};

		// 			let dianliData = await getionoeffect({
		// 				params: {
		// 					lon: info.host.longitude,
		// 					lat: info.host.latitude,
		// 				},
		// 			});

		// 			if (dianliData.code == 200) {
		// 				dianli[info.sn].label = dianliData.data.label;
		// 			}
		// 		}
		// 	}
		// })();
		if (info.host && info.host.longitude && info.host.latitude) {
			let lon = info.host.longitude;
			let lat = info.host.latitude;
			const gps = wgs84togcj02(Number(info.host.longitude), Number(info.host.latitude));
			// if(state.centerPointData.center.length == 0){
			state.centerPointData.center = [gps[0], gps[1]];
			// }
			info.host.longitude = gps[0];
			info.host.latitude = gps[1];

			if (!state.positionData.dock) {
				state.positionData.dock = {};
			}
			if (!state.positionData.dock[info.sn]) {
				state.positionData.dock[info.sn] = {
					longitude: info.host.longitude,
					latitude: info.host.latitude,
					lon,
					lat,
					mode_code: info.host.mode_code,
					marker: null,
					dock_sn: info.sn,
				};
			}
			info.host.sub_device &&
				(() => {
					correspondence[info.host.sub_device.device_sn] = info.sn;
				})();
		}
		if (!state.deviceState.dockInfo[info.sn]) {
			state.deviceState.dockInfo[info.sn] = {} as DockOsd;
		}
		state.deviceState.currentSn = info.sn;
		state.deviceState.currentType = EDeviceTypeName.Dock;
		const dock = state.deviceState.dockInfo[info.sn];

		if (!info.host.sdr && !info.host.job_number) {
			if (!dock.basic_osd) {
				dock.basic_osd = info.host;
			} else {
				let host = { ...info.host };
				// delete host.latitude
				// delete host.longitude
				dock.basic_osd = {
					...dock.basic_osd,
					...host,
				};
			}
			// console.log("dock",dock);
		}

		// if(!dock.basic_osd && !info.host.sdr && !info.host.job_number){
		//   dock.basic_osd = info.host
		// }

		if (info.host.sdr) {
			dock.link_osd = info.host;
		}
		if (info.host.job_number !== undefined) {
			dock.work_osd = info.host;
		}
	},
	SET_SELECT_WAYLINE_INFO_DIALOG(state, info) {
		state.modalData.wayline = info;
	},
	SET_SELECT_DEVICE_INFO_DIALOG(state, info) {
		state.modalData.device = info;
	},
	SET_DRAW_VISIBLE_INFO(state, bool) {
		state.drawVisible = bool;
	},
	SET_MAP_ELEMENT_CREATE(state, info) {
		state.wsEvent.mapElementCreat = info;
	},
	SET_MAP_ELEMENT_UPDATE(state, info) {
		state.wsEvent.mapElementUpdate = info;
	},
	SET_MAP_ELEMENT_DELETE(state, info) {
		state.wsEvent.mapElementDelete = info;
	},
	SET_DEVICE_ONLINE(state, info) {
		state.deviceStatusEvent.deviceOnline = info;
	},
	SET_DEVICE_OFFLINE(state, info) {
		state.deviceStatusEvent.deviceOffline = info;
		delete state.deviceState.gatewayInfo[info.sn];
		delete state.deviceState.deviceInfo[info.sn];
		delete state.deviceState.dockInfo[info.sn];

		delete state.positionData.dock[info.sn];
		delete state.positionData.gateway[info.sn];
		delete state.positionData.drone[info.sn];

		delete state.hmsInfo[info.sn];
		// delete state.markerInfo.coverMap[info.sn]
		// delete state.markerInfo.pathMap[info.sn]
	},
	SET_OSD_VISIBLE_INFO(state, info) {
		state.osdVisible = info;
	},
	SET_OSD_DATA_INFO(state, info) {
		state.osdDataInfo = info;
	},

	SET_SELECT_WAYLINE_INFO(state, info) {
		state.waylineInfo = info;
	},
	SET_SELECT_DOCK_INFO(state, info) {
		state.dockInfo = info;
	},
	SET_DEVICE_HMS_INFO(state, info) {
		const hmsList: Array<DeviceHms> = state.hmsInfo[info.sn];
		state.hmsInfo[info.sn] = info.host.concat(hmsList ?? []);
	},
	SET_DEVICES_CMD_EXECUTE_INFO(state, info) {
		// 保存设备指令ws消息推送
		if (!info.sn) {
			return;
		}
		if (state.devicesCmdExecuteInfo[info.sn]) {
			const index = state.devicesCmdExecuteInfo[info.sn].findIndex((cmdExecuteInfo) => cmdExecuteInfo.biz_code === info.biz_code);
			if (index >= 0) {
				// 丢弃前面的消息
				if (state.devicesCmdExecuteInfo[info.sn][index].timestamp > info.timestamp) {
					return;
				}
				state.devicesCmdExecuteInfo[info.sn][index] = info;
			} else {
				state.devicesCmdExecuteInfo[info.sn].push(info);
			}
		} else {
			state.devicesCmdExecuteInfo[info.sn] = [info];
		}
	},
	SET_MQTT_STATE(state, mqttState) {
		state.mqttState = mqttState;
	},
	SET_CLIENT_ID(state, clientId) {
		state.clientId = clientId;
	},
	GET_ROUTE_INFO(state, routeInfo) {
		state.waypoint.routeInfo = routeInfo;
	},
	SET_ROUTE_INFO(state, ifAutomatically) {
		state.ifAutomatically = ifAutomatically;
	},
	SET_POLYGIN_OPTIONS(state, polylineOptions) {
		state.polylineOptions = polylineOptions;
	},
	SET_POLYGON_OPTIONS(state, polylineOptions) {
		state.polygonOptions = polylineOptions;
	},
	SET_MAP_CENTER(state, data) {
		state.waypoint.map.center = data;
	},
	SET_START_POINT(state, startPoint) {
		state.waypoint.startPoint = startPoint;
	},
	SET_SHIP_OBJ(state, val) {
		state.shipObject[val.id] = val;
		if (val.visible) {
			state.positionData.ship[val.id] = {
				longitude: state.shipObject[val.id].longitude,
				latitude: state.shipObject[val.id].latitude,
				marker: null,
				name: val.name,
			};
		} else {
			state.positionData.ship[val.id] = {};
		}
	},
	SET_CURRENT_SHIP(state, val) {
		state.currentShip = val;
	},
};

const actions: ActionTree<RootStateType, RootStateType> = {
	async getAllElement({ commit }) {
		const result = await getLayers({
			groupId: '',
			isDistributed: true,
		});
		commit('SET_LAYER_INFO', result.data?.list);
		console.log(result);
	},
	updateElement({ state }, content: { type: 'is_check' | 'is_select'; id: string; bool: boolean }) {
		const key = content.id.replaceAll('resource__', '');
		const type = content.type;
		const layers = state.Layers;
		const layer = layers.find((item) => item.id === key);
		if (layer) {
			layer[type] = content.bool;
		}
	},
	setLayerInfo({ state }, layers) {
		// const layers = state.Layers
		const obj: {
			[key: string]: string;
		} = {};
		layers.forEach((layer) => {
			if (layer.type === LayerType.Default) {
				obj.default = layer.id;
			} else {
				if (layer.type === LayerType.Share) {
					obj.share = layer.id;
				}
			}
		});
		state.layerBaseInfo = obj;
	},
	getLayerInfo({ state }, id: string) {
		return state.layerBaseInfo[id];
	},
};

const storeOptions: StoreOptions<RootStateType> = {
	state: initStateFunc(),
	getters,
	mutations,
	actions,
};

const rootStore = createStore(storeOptions);

export default rootStore;

export const storeKey: InjectionKey<Store<RootStateType>> = Symbol('');

type AllStateStoreTypes = RootStateType & {
	// moduleName: moduleType
};

export function useMyStore<T = AllStateStoreTypes>() {
	console.info('storeKey', storeKey);
	console.info('useStore', useStore);
	console.info('useStore<T>(storeKey)', useStore<T>(storeKey));
	return useStore<T>(storeKey);
}
