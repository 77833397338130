<template>
	<div class="project-wayline-wrapper height_100">
		<div style="height: 50px; line-height: 50px; border-bottom: 1px solid #4f4f4f; font-weight: 450">
			<a-row>
				<a-col :span="1"></a-col>
				<a-col :span="15">航线库</a-col>
				<a-col :span="8" v-if="importVisible" class="flex-row flex-justify-end flex-align-center">
					<a-upload
						accept=".kmz" 
						name="file" 
						:multiple="false" 
						:before-upload="beforeUpload" 
						:show-upload-list="false" 
						:customRequest="uploadFile">
						<a-button type="text" style="color: white">
							<SelectOutlined />
						</a-button>
					</a-upload>
					<a-tooltip title="开始创建航线">
						<a-button type="text" style="color: white" @click="createWayPointDiaLog">
							<template #icon>
								<PlusOutlined />
							</template>
						</a-button>
					</a-tooltip>
				</a-col>
			</a-row>
		</div>
		<div>
			<a-row class="tiaojian" v-if="importVisible">
				<a-col :span="24">
					<div>
						<a-row>
							<a-col :span="12">
								<a-select v-model:value="deviceValue" :options="deviceAllOptions" dropdown-class-name="dropdown-class"> </a-select>
							</a-col>
							<a-col :span="12">
								<a-select v-model:value="cameraValue" :options="cameraAllOption" dropdown-class-name="dropdown-class"> </a-select>
							</a-col>
						</a-row>
					</div>
				</a-col>
			</a-row>
		</div>
		<div style="overflow: scroll;flex:1">
			<a-spin :spinning="loading" :delay="300" tip="downloading" size="large">
				<div class="scrollbar uranus-scrollbar" v-if="waylinesData.data.length !== 0">
					<div v-for="wayline in waylinesData.data" :key="wayline.id">
						<div class="wayline-panel pt5" style="" @click="selectRoute(wayline)">
							<div class="title">
								<a-tooltip :title="wayline.name">
									<div class="pr10 w120" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
										{{ wayline.name }}
									</div>
								</a-tooltip>
								<div class="ml10"><UserOutlined /></div>
								<a-tooltip :title="wayline.user_name">
									<div class="ml5 pr10" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
										{{ wayline.user_name }}
									</div>
								</a-tooltip>
								<div class="fz20">
									<a-dropdown>
										<a style="color: white">
											<EllipsisOutlined />
										</a>
										<template #overlay>
											<a-menu inlineCollapsed=true theme="dark" class="more" style="background: #3c3c3c">
												<a-menu-item @click="downloadWayline(wayline.id, wayline.name)">
													<span>下载</span>
												</a-menu-item>
												<a-menu-item @click="showWaylineTip(wayline.id)">
													<span>删除</span>
												</a-menu-item>
												<a-menu-item @click="tongbu(wayline.id, wayline.name)">
													<span>同步</span>
												</a-menu-item>
											</a-menu>
										</template>
									</a-dropdown>
								</div>
							</div>
							<div class="ml10 mt5" style="color: hsla(0, 0%, 100%, 0.65)">
								<span><i class="iconfont icon-wurenji" /></span>
								<span class="ml5">{{ Object.keys(EDeviceType)[Object.values(EDeviceType).indexOf(wayline.drone_model_key)] }}</span>
								<span class="ml10"><CameraFilled style="border-top: 1px solid; padding-top: -3px" /></span>
								<span class="ml5" v-for="payload in wayline.payload_model_keys" :key="payload.id">
									{{ Object.keys(EDeviceType)[Object.values(EDeviceType).indexOf(payload)] }}
								</span>
							</div>
							<div class="mt5 ml10" style="color: hsla(0, 0%, 100%, 0.35)">
								<span class="">时间 {{ new Date(wayline.update_time).toLocaleString() }}</span>
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					<a-empty description="暂无数据" :image-style="{ height: '60px', marginTop: '60px' }" />
				</div>
				<a-modal
					v-model:visible="deleteTip"
					width="450px"
					:closable="false"
					:maskClosable="false"
					centered
					:okButtonProps="{ danger: true }"
					@ok="deleteWayline"
				>
					<p class="pt10 pl20" style="height: 50px">航线文件一旦删除就无法恢复</p>
					<template #title>
						<div class="flex-row flex-justify-center">
							<span>删除</span>
						</div>
					</template>
				</a-modal>
			</a-spin>
		</div>
		<waypointDialog :visible="waypointDialogVisible" @clone="waypointDialogVisible = false" />
	</div>
</template>

<script lang="ts" setup>
import { message } from 'ant-design-vue';
import { onMounted, onUpdated, reactive, ref, inject, nextTick } from 'vue';
import { deleteWaylineFile, downloadWaylineFile,downloadFileData,getFile, getWaylineFiles, importKmzFile, getRouteInfo } from '/@/api/wayline';
import { ELocalStorageKey, ERouterName } from '/@/types';
import { EllipsisOutlined, RocketOutlined, CameraFilled, UserOutlined, SelectOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { EDeviceType } from '/@/types/device';
import { useMyStore } from '/@/store';
import { WaylineFile } from '/@/types/wayline';
import { downloadFile } from '/@/utils/common';
import { IPage } from '/@/api/http/type';
import waypointDialog from '/@/components/waypointDialog.vue';

import { getRoot } from '/@/root';
import { useGMapManage } from '/@/hooks/use-g-map';
import { importKmzTongBuFile } from '/@/api/fuya/wayline';

const loading = ref(false);
const store = useMyStore();
const pagination: IPage = {
	page: 1,
	total: 0,
	page_size: 200,
};

const waylinesData = reactive({
	data: [] as WaylineFile[],
});

const waypointDialogVisible = ref(false);
const root = getRoot();
let workspaceId = '';
const deleteTip = ref(false);
const deleteWaylineId = ref<string>('');
const canRefresh = ref(true);
const importVisible = ref<boolean>(root.$router.currentRoute.value.name === ERouterName.WAYLINE);

// 创建航线线路的弹框
let createWayPointDiaLog = () => {
	// 开启航线线路的弹框
	waypointDialogVisible.value = true;
};

// 进入航点的页面
onMounted(async () => {
	// 获取设备数据
	workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!;
	setTimeout(() => {
		// setTimeout(()=>{
		getWaylines();
		// },0)
	}, 0);
	
	// 加载线路
});


const daoruhangxian = async (data:any,name:string) => {
	console.log("name",name)
	console.log("data",data)
	const fileData = new FormData();
	fileData.append("roleId","null")
	fileData.append("deviceNumber","C0FBF9C757EC")
	fileData.append("fileType","20")
	fileData.append("heightType","0")
	fileData.append("files",data,data.name)
	let res = await importKmzTongBuFile(fileData)
	console.log(res)
	if(res){
	  return message.info("同步成功")
	}
}

const tongbu = async (waylineId:string, name:string) => {
	let res = await downloadFileData(workspaceId, waylineId)
		
	if (!res) {
		return;
	}
	if(res.code == 0){
		let url = res.data.url
		let files = await getFile(url)
		console.info("files",files)
		const data = new Blob([files.data], { type: 'application/zip' });
		console.info("Blob",data)
		const filesData = new File([data],name + ".kmz")
		console.info("filesData",filesData)

		await daoruhangxian(filesData,name + ".kmz")

	}
		
}

const deviceValue = ref(0);

const deviceAllOptions = ref([
	{
		label: '所有飞行器',
		value: 0,
	},
	{
		label: 'M30系列',
		value: 1,
	},
	{
		label: 'Mavic 3 行业系列',
		value: 2,
	},
]);

const cameraValue = ref(0);

const cameraAllOption = ref([
	{
		label: '所有负载',
		value: 0,
	},
	{
		label: 'M30 相机',
		value: 1,
	},
	{
		label: 'M30T 相机',
		value: 2,
	},
	{
		label: 'Mavic 3E 相机',
		value: 3,
	},
	{
		label: 'Mavic 3T 相机',
		value: 4,
	},
]);

onUpdated(() => {
	const element = document.getElementsByClassName('scrollbar').item(0) as HTMLDivElement;
	const parent = element?.parentNode as HTMLDivElement;
	setTimeout(() => {
		console.info(element, parent);
		if (element?.scrollHeight < parent?.clientHeight && pagination.total > waylinesData.data.length) {
			if (canRefresh.value) {
				pagination.page++;
				getWaylines();
			}
		} else if (element && element.className.indexOf('height-100') === -1) {
			element.className = element.className + ' height-100';
		}
	}, 300);
});

watch(
	() => deviceValue.value,
	() => {
		getWaylines();
	},
	{
		deep: true,
	}
);

watch(
	() => cameraValue.value,
	() => {
		getWaylines();
	},
	{
		deep: true,
	}
);

function getWaylines() {
	if (!canRefresh.value) {
		return;
	}
	canRefresh.value = false;
	getWaylineFiles(workspaceId, {
		page: pagination.page,
		page_size: pagination.page_size,
		order_by: 'update_time desc',
		droneModelKey: deviceValue.value == 0 ? '' : deviceValue.value,
		payloadModelKeys: cameraValue.value == 0 ? '' : cameraValue.value,
	})
		.then((res) => {
			if (res.code !== 0) {
				return;
			}
			waylinesData.data = res.data.list;
			// res.data.list.forEach((wayline: WaylineFile) => waylinesData.data.push(wayline));
			pagination.total = res.data.pagination.total;
			pagination.page = res.data.pagination.page;
		})
		.finally(() => {
			canRefresh.value = true;
		});
}

function showWaylineTip(waylineId: string) {
	deleteWaylineId.value = waylineId;
	deleteTip.value = true;
}

function deleteWayline() {
	deleteWaylineFile(workspaceId, deleteWaylineId.value).then((res) => {
		if (res.code === 0) {
			// message.success("Wayline file deleted");
		}
		deleteWaylineId.value = '';
		deleteTip.value = false;
		pagination.total--;
		waylinesData.data = [];
		getWaylines();
	});
}

async function downloadWayline(waylineId: string, fileName: string) {
	loading.value = true;
	let res= await downloadFileData(workspaceId, waylineId)
	console.info("res",res)
	if(res.code == 0){
		let url = res.data.url
		const lable = document.createElement('a');
		lable.href = url

		lable.download = fileName;
		lable.click();
		URL.revokeObjectURL(lable.href);
		loading.value = false;
	}
	




	/**
	
	downloadWaylineFile(workspaceId, waylineId)
		.then((res) => {
			if (!res) {
				return;
			}
			const data = new Blob([res], { type: 'application/zip' });
			downloadFile(data, fileName + '.kmz');
		})
		.finally(() => {
			loading.value = false;
		});
	
	*/
}

// onMounted(() => {
//   // const routeId = store.state.waylineInfo.id
//   getOneRouteInfo(workspaceId,store.state.waylineInfo.id)
// });

// 获取单条航线信息
const routeInfo = ref([]);
const getOneRouteInfo = async (workspaceId: string, waylineId: string) => {
	const data = await getRouteInfo(workspaceId, waylineId);
	store.commit('GET_ROUTE_INFO', data.data);
	routeInfo.value = data.data;
	console.info('routeInfo', routeInfo.value);
};

function selectRoute(wayline: WaylineFile) {
	if (importVisible.value) {
		// 跳转，不添加记录
		console.info('root.$route', root.$route);
		root.$router
			.replace({
				name: ERouterName.CREATEWAYPOINTDATA,
				query: {
					id: wayline.id,
					baseUrl: root.$route.fullPath,
				},
			})
			.then(() => {
				location.reload();
			});
	} else {
		store.commit('SET_SELECT_WAYLINE_INFO_DIALOG', true);
		store.commit('SET_SELECT_WAYLINE_INFO', wayline);
		getOneRouteInfo(workspaceId, wayline.id);
	}
}

function onScroll(e: any) {
	const element = e.srcElement;
	console.info('element', element);
	// if (
	// 	element.scrollTop + element.clientHeight === element.scrollHeight &&
	// 	Math.ceil(pagination.total / pagination.page_size) > pagination.page &&
	// 	canRefresh.value
	// ) {
	// 	pagination.page++;
	// 	getWaylines();
	// }
}

interface FileItem {
	uid: string;
	name?: string;
	status?: string;
	response?: string;
	url?: string;
}

interface FileInfo {
	file: FileItem;
	fileList: FileItem[];
}
const fileList = ref<FileItem[]>([]);

function beforeUpload(file: FileItem) {
	fileList.value = [file];
	loading.value = true;
	return true;
}
const uploadFile = async () => {
	fileList.value.forEach(async (file: FileItem) => {
		console.info("file",file)
		const fileData = new FormData();
		fileData.append('file', file, file.name);
		// daoruhangxian(file,file.name)
		await importKmzFile(workspaceId, fileData)
			.then((res) => {
				if (res.code === 0) {
					// message.success(`${file.name} file uploaded successfully`);
					canRefresh.value = true;
					getWaylines();
				}
			})
			.finally(() => {
				loading.value = false;
				fileList.value = [];
			});
	});
};
</script>
<style lang="scss">
.dropdown-class {
	.ant-select-item-option-selected,
	.ant-select-item-option-active {
		color: #000;
		background-color: #eee;
	}
	.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
		background-color: #eee !important;
	}
}
</style>
<style lang="scss" scoped>


.pt5{
	padding-top: 5px
}
.w120{
	width: 120px;
}
.w80 {
	width: 80px;
}

.tiaojian {
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 5px;
	::v-deep .ant-select-selector {
		background-color: #3c3c3c !important;
		color: #fff;
		border: 1px solid #3c3c3c !important;
		// box-shadow: none;
	}
	::v-deep .ant-select-arrow {
		color: #fff;
	}
}
.wayline-panel {
	background: #3c3c3c;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	height: 90px;
	width: 95%;
	font-size: 13px;
	border-radius: 2px;
	cursor: pointer;
	.title {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 30px;
		font-weight: bold;
		margin: 0px 10px 0 10px;
	}
}

.tiaojian {
	::v-deep .ant-select {
		display: block;
	}
}

.height_100 {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
}
.uranus-scrollbar {
	// overflow: auto;
	scrollbar-width: thin;
	scrollbar-color: #c5c8cc transparent;
	height: 100%;
}

.iconfont {
	font-size: 12px;
}
</style>
