/*
 * @Date: 2023-09-25 10:35:10
 * @LastEditors: gejia 905034981@qq.com
 * @LastEditTime: 2024-06-04 17:14:32
 * @Description: 文件注释
 * @Author: jiangchao
 * @FilePath: \redfly-web\src\api\ai.ts
 */
import moment from 'moment';
import request from '/@/api/http/request';

const jsonByUrl = function () {
	return function (obj: any, isFirst: boolean = true) {
		let url = '';
		for (let [key, value] of Object.entries(obj)) {
			url += `${isFirst ? '?' : '&'}${key}=${value}`;
		}
		return url;
	};
};

export const getAiLiveData = () => {
	let url = `/aiIdentify/checkServices`;
	return requestGet(url);
};
export interface pageParams {
	page: number;
	pageSize: number;
	id?: string;
}
//查询车牌
export const getPlateNumber = async (params: pageParams) => {
	let url = `/gateway/ai/plate-number/page`;
	let res = await request.get(url, { params });
	return res.data;
};
//新增车牌号
export const addPlateNumber = async (data: { plateNumber: string; aiTypeId: string }) => {
	let url = `/gateway/ai/plate-number/insert`;
	let res = await request.post(url, data);
	return res.data;
};
//编辑车牌号
export const editPlateNumber = async (data: { id: string; plateNumber: string; aiTypeId: string }) => {
	let url = `/gateway/ai/plate-number/update`;
	let res = await request.put(url, data);
	return res.data;
};
//删除车牌号
export const deletePlateNumber = async (params: { ids: string[] }) => {
	let res = await request.delete(`/gateway/ai/plate-number/delete?ids=` + params.ids.join('&ids'));
	return res.data;
};
export const getTool = (typeId: any) => {
	let url = `/aiIdentify/train/getAiMarkTools?typeId=${typeId}`;
	return requestGet(url, {
		responseType: 'blob',
	});
};

export const startTraining = (query: { typeId: number; fileId: string }) => {
	let url = `/aiIdentify/train/startTrain`;
	let firstIndex = true;
	for (let [queryKey, queryValue] of Object.entries(query)) {
		url += `${firstIndex ? '?' : '&'}${queryKey}=${queryValue}`;
		firstIndex = false;
	}
	return requestGet(url);
};

export const getSchedule = (typeId: any, rindexByid: string | number) => {
	let url = `/aiIdentify/train/getTrainProgress?typeId=${typeId}&index=${rindexByid}`;
	return requestGet(url);
};

export const getAllData = () => {
	let url = `/aiIdentify/train/getTrain`;
	return requestGet(url);
};

export const removeData = (typeId: any) => {
	let url = `/aiIdentify/train/deleteTrain?typeId=${typeId}`;
	return requestGet(url);
};

export const changeData = (typeId: any) => {
	let url = `/aiIdentify/train/replaceTrain?typeId=${typeId}`;
	return requestGet(url);
};

export const getStorageConfig = () => {
	let url = `/system/base/getStorageConfig`;
	return requestGet(url);
};

interface UploadFileByOssObject {
	ossObject: string;
}

export const uploadFileByOssObject = (body: UploadFileByOssObject) => {
	let url = `/system/base/uploadFileByOssObject?ossObject=${body.ossObject}`;
	return requestPost(url);
};

// 设置短信通知
export const setSmsNotify = (body: { id: string; isNotify: string }) => {
	let url = `/admin-api/system/user/setNotify`;
	return requestPost(url, body);
};

export interface OpenAiStartLives {
	workSpaceId: string;
	sn: string;
	flag: number;
	serviceName?: number;
	cameraMode: 'ir' | 'normal' | 'zoom';
	sharpness: number;
}

export const openAiStartLive = (data: OpenAiStartLives) => {
	let url = `/aiIdentify/getCapacityThenStopLiveThenStartLive`;
	let isFirst = true;
	for (let [key, value] of Object.entries(data)) {
		url += `${isFirst ? '?' : '&'}${key}=${value}`;
		isFirst = false;
	}
	return requestGet(url);
};

export interface StopAiLives {
	// serviceName:string
	// rtmpUrl:string
	// userName:string
	sn: string;
}

export const stopAiLive = (data: StopAiLives) => {
	let url = `/aiIdentify/capacityThenStopLiveThenStopLive`;
	let isFirst = true;
	for (let [key, value] of Object.entries(data)) {
		url += `${isFirst ? '?' : '&'}${key}=${value}`;
		isFirst = false;
	}
	return requestGet(url);
};

const requestGet = (url: string, config = {}) => {
	return request.get(url, config);
};

const requestPost = (url: string, data: any = {}, config: any = {}) => {
	return request.post(url, data, config);
};

const requestPut = (url: string, data: any = {}, config: any = {}) => {
	return request.put(url, data, config);
};
/**
 * 根据Ai类型获取线索图片
 * @param { }
 * @returns
 */
const workSpaceId = localStorage.getItem('workspace_id')!;
export const getAllClueByAiType = async (data: {}) => {
	try {
		let res = await request.get(`/task/api/v1/task/${workSpaceId}/getAllClueByAiType`, { params: data });
		return res.data;
	} catch (error) {
		throw error;
	}
};
/**
 * 获取多个Ai类型配置
 * @param { }
 * @returns
 */
export const getAiConfigByaiTypes = async (data: {}) => {
	try {
		let res = await request.get(`/api/v1/ai/config/lists`, { params: data });
		return res.data;
	} catch (error) {
		throw error;
	}
};
/**
 * 更新Ai类型配置
 * @param { }
 * @returns
 */
export const updateByAiType = async (AiType:any,data: {}) => {
	try {
		let res = await request.put(`/api/v1/ai/config/updateByAiType/${AiType}`, data);
		return res.data;
	} catch (error) {
		throw error;
	}
};
